import axios from '@/utils/https'
import request from '@/utils/request'

const fetch = (
    path = '',
    params = {},
    restConfig = {
        method: 'post',
        'Content-Type': 'application/json'
    }
) =>
    request('accountingServer', path, {
        params,
        ...restConfig
    })

export default {
    actualPage (query) {
        // 数据补录导入表分页
        return axios.fetch(
            'accountingServer',
            // '/accounting/budgetactual-page',
            '/accounting/actual-import-page',
            query
        )
    },
    actualDelete (query, type) {
        // 删除补录数据
        return axios.fetch(
            'accountingServer',
            '/accounting/actual-import-delete',
            // '/accounting/budgetactual-delete',
            query,
            type
        )
    },
    getBudgetProjects () {
        // 获取收支项目
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-item-list'
        )
    },
    getAccoutingProjects (query) {
        // 组织收支项目关系表List
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-item-list',
            query
        )
    },
    getBudgetProjectsByPage (query) {
        // 获取收支项目--分页
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-item-page',
            query
        )
    },
    addBudgetProject (query) {
        // 新增收支项目
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-item-add',
            query,
            'json'
        )
    },
    delBudgetProject (query) {
        // 删除收支项目
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-item-change',
            query,
            'json'
        )
    },
    // 获取收支项目列表2
    getBudgetList () {
        return fetch('/accounting/accounting-item-list')
    },
    // 获取收支项目列表
    getBudgetListCommon () {
        return fetch('/accounting/accounting-item-list-common')
    },
    // 获取管会设置
    getAccountSetting () {
        return fetch('/accounting-setting/get-common-setting')
    },
    // 修改规划设置
    setAccountSetting (params) {
        return fetch('/accounting-setting/update', params, {
            customConfig: {
                openLoading: true
            },
            dataType: 'form'
        })
    },
    // 获取收支项目列表+系数
    getBudgetListCoefficient () {
        return fetch('/accounting/accounting-item-list-coefficient')
    },
    // 收支项目排序
    budgetProjectSort (param) {
        return fetch('/accounting/accounting-item-priority-change', param)
    },
    // 收支项目设置排序
    budgetProjectSettingSort (param) {
        return fetch('/accounting/accounting-group-priority-change', param)
    },
    editBudgetProject (query) {
        // 编辑收支项目
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-item-edit',
            query,
            'json'
        )
    },
    // 查询组织下收支项目
    getGroupBudgetList (query) {
        // 编辑收支项目
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-group-list',
            query
        )
    },
    // 添加组织下收支项目
    addGroupBudgetList (query) {
        // 编辑收支项目
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-group-add',
            query,
            'json'
        )
    },
    // /accounting/accounting-group-check 收支项目设置检验
    accountGroupCheck (query) {
        // 编辑收支项目
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-group-check',
            query,
            'json'
        )
    },
    // ----------预算填报----------
    getFillList (query) {
        // 预算期间数据填报表查询List
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-list',
            query
        )
    },
    getFillListByPage (query) {
        // 预算期间数据填报表查询List-分页
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-page',
            query
        )
    },
    getPeriodList (query) {
        // 预算时间期间定义表查询List
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-period-list',
            query
        )
    },
    delFill (query) {
        // 删除预算填报
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-delete',
            query,
            'json'
        )
    },
    submitFill (query) {
        // 提交审核预算填报
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-submit',
            query,
            'json'
        )
    },
    //
    editFill (query) {
        // 修改预算填报
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-edit',
            query,
            'json'
        )
    },
    approveFill (query) {
        // 审核预算填报
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-check',
            query,
            'json'
        )
    },
    rejectFill (query) {
        // 驳回预算填报
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-rejected',
            query,
            'json'
        )
    },
    withdrawFill (query) {
        // 撤回预算填报
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-revocation',
            query,
            'json'
        )
    },
    addPeriodList (query) {
        // 预算时间期间定义表新增
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-period-add',
            query,
            'json'
        )
    },
    addFill (query) {
        // 预算期间数据填报表新增
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-budget-fill-add',
            query,
            'json'
        )
    },
    getFillItemList (query) {
        // 预算填报收支项目表查询List
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-fill-item-list',
            query
        )
    },
    // ----------组织收支项目关系-----
    delGroupBudget (query) {
        // 组织收支项目关系表删除
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-group-delete',
            query,
            'json'
        )
    },
    editGroupBudget (query) {
        // 组织收支项目关系表编辑
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-group-edit',
            query,
            'json'
        )
    },
    copyGroupBudget (query) {
        // 组织收支项目复制规则
        return axios.fetch(
            'accountingServer',
            '/accounting/accounting-group-copy',
            query,
            'json'
        )
    },
    // --------------END------------
    //                            _ooOoo_
    //                           o8888888o
    //                           88" . "88
    //                           (| -_- |)
    //                            O\ = /O
    //                        ____/'---'\____
    //                       .  ' \\| |// '  .
    //                       / \\||| : |||// \
    //                     / _||||| -:- |||||- \
    //                       | | \\\ - /// | |
    //                     | \_| ''\---/'' |_/  |
    //                      \ .-\__ `-` ___/-. /
    //                   ___`. .' /--.--\ '. .`___
    //                ."" '< '.___\_<|>_/___.' >' "".
    //               | | : `- `.;`\ _ /`;.`/ -` : | |
    //                 \ \ `-. \_ __\ /__ _/ .-` / /
    //         ======'-.____`-.___\_____/___.-`____.-'======
    //                            '=---='
    //      ......................实际报表.......................
    // 实际管理报表产品层
    getActualListProduct (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/list-product',
            query
        )
    },
    // 实际管理报表非叶子节点
    getActualListNotLeaf (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/list-project',
            query
        )
    },
    // 实际管理报表详情-产品的收支项目列表
    getActualListProductDetail (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/list-product-detail',
            query
        )
    },
    // TOP5商品经营（叶子节点）---实际报表的数据统计
    getTop5Leaf (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/top5-commodity-management',
            query
        )
    },
    // TOP5商品经营（非叶子节点）----实际报表中的数据统计
    getTop5NotLeaf (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/top5-commodity-management-not-leaf',
            query
        )
    },
    // 实际管理报表叶子组织-下面产品列表
    getActualListLeafDetail (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/list-project-detail',
            query
        )
    },
    // 实际管理报表非叶子组织-下面的店铺列表
    getActualListNotLeafDetail (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/list-project-detail-not-leaf',
            query
        )
    },

    // 实际报表刷新
    actualReportRefresh (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/accounting-order-sum-refresh',
            query
        )
    },

    // 财务经营-产品边际贡献（工作台财务经营数据）----实际报表
    getFinanceData (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/list-financial-operation',
            query
        )
    },

    // 财务经营-净利润（工作台财务经营数据）----实际报表
    getFinanceDataSurplus (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-order-sum/list-financial-operation-surplus',
            query
        )
    },
    //                            _ooOoo_
    //                           o8888888o
    //                           88" . "88
    //                           (| -_- |)
    //                            O\ = /O
    //                        ____/'---'\____
    //                       .  ' \\| |// '  .
    //                       / \\||| : |||// \
    //                     / _||||| -:- |||||- \
    //                       | | \\\ - /// | |
    //                     | \_| ''\---/'' |_/  |
    //                      \ .-\__ `-` ___/-. /
    //                   ___`. .' /--.--\ '. .`___
    //                ."" '< '.___\_<|>_/___.' >' "".
    //               | | : `- `.;`\ _ /`;.`/ -` : | |
    //                 \ \ `-. \_ __\ /__ _/ .-` / /
    //         ======'-.____`-.___\_____/___.-`____.-'======
    //                            '=---='
    //      ......................日常报表.......................

    // 日常报表index-产品的收支项目列表
    getDailyReportProductList (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-sum/list-product',
            query
        )
    },
    // 日常报表index-非叶子组织-下面的店铺列表
    getDailyReportNotLeafList (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-sum/list-project',
            query
        )
    },
    // 日常报表detail产品层-下面收支项目列表
    getDailyReportProductDetail (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-sum/list-product-detail',
            query
        )
    },
    // 日常报表detail叶子组织-下面的产品列表
    getDailyReportLeafDetail (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-sum/list-project-detail',
            query
        )
    },
    // 日常报表detail非叶子组织-下面组织店铺列表
    getDailyReportNotLeafDetail (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-sum/list-project-detail-not-leaf',
            query
        )
    },
    // 日常报表数据刷新
    dailyReportRefresh (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-sum/accounting-sum-refresh',
            query
        )
    },
    // 财务经营-产品边际贡献（工作台财务经营数据）---日常报表
    // 【日常报表中的数据】 运营工作台--财务经营统计
    getDailyFinanceData (query) {
        return fetch('/accounting-sum/list-financial-operation', query, {
            dataType: 'form'
        })
    },

    // 财务经营-净利润（工作台财务经营数据）----日常报表
    // 【日常报表中的数据】 运营经理、分销工作台--财务经营统计
    getDailyFinanceDataSurplus (query) {
        return fetch(
            '/accounting-sum/list-financial-operation-surplus',
            query,
            { dataType: 'form' }
        )
    },
    /**
     * 【日常报表中的数据】---top5商品经营图统计
     * 1.叶子组织：/accounting-sum/top5-commodity-management
     * 2.非叶子组织：/accounting-sum/top5-commodity-management-not-leaf
     */
    getDailyReportTop5Leaf (query) {
        return axios.fetch(
            'accountingServer',
            '/accounting-sum/top5-commodity-management',
            query
        )
    },
    getDailyReportTop5NotLeaf (query) {
        return fetch(
            '/accounting-sum/top5-commodity-management-not-leaf',
            query,
            { dataType: 'form' }
        )
    },
    // 项目目标达成接口
    goalAttainment (query) {
        return fetch('/accounting-sum/goal-attainment', query, {
            dataType: 'form'
        })
    },
    // 页面账单拉取，同步，拆分，汇总，计算
    jkyDataPull (params) {
        return fetch('/trade-bill/get-trade-bill', params)
    },
    coefficientPage (query) {
        // 系数数据分页
        return axios.fetch(
            'accountingServer',
            '/accounting-coefficien/coefficient-page',
            query
        )
    },
    coefficientDelete (query, type) {
        // 系数数据删除
        return axios.fetch(
            'accountingServer',
            '/accounting-coefficien/coefficient-delete',
            query,
            type
        )
    },
    coefficientUpdate (query) {
        // 组织收支项目复制规则
        return axios.fetch(
            'accountingServer',
            '/accounting-coefficien/coefficient-update',
            query,
            'json'
        )
    },
    getAccountingPlans (params) {
        // 获取规划列表分页
        return fetch('/accounting-plan/page-accounting-plan', params, {
            dataType: 'form'
        })
    },
    addAccountingPlan (params) {
        // 规划头批量新建
        return fetch('/accounting-plan/plan-add', params, {
            customConfig: {
                openLoading: true
            }
        })
    },
    submitPlan (params) {
        // 规划提交
        return fetch('/accounting-plan/submit', params)
    },
    checkPlan (params) {
        // 规划审核
        return fetch('/accounting-plan/check', params)
    },
    againPlan (params) {
        // 规划重新填报
        return fetch('/accounting-plan/again', params)
    },
    cancelPlan (params) {
        // 规划取消
        return fetch('/accounting-plan/cancel', params)
    },
    revocationPlan (params) {
        // 规划撤回
        return fetch('/accounting-plan/revocation', params)
    },
    // 报表产品层
    fillReportProductList (params) {
        // 规划头批量新建
        return fetch('/accounting-plan/list-product', params, {
            customConfig: {
                openLoading: true
            },
            dataType: 'form'
        })
    },
    // 报表叶子组织详情
    fillReportLeaftDetail (params) {
        // 规划头批量新建
        return fetch('/accounting-plan/list-project-detail', params, {
            customConfig: {
                openLoading: true
            },
            dataType: 'form'
        })
    },
    // 报表产品详情
    fillReportProductDetail (params) {
        // 规划头批量新建
        return fetch('/accounting-plan/list-product-detail', params, {
            customConfig: {
                openLoading: true
            },
            dataType: 'form'
        })
    },
    getAccountingLogPage (params) {
        // 获取规划列表分页
        return fetch('/accounting-log/accounting-log-page', params, {
            dataType: 'form'
        })
    }
}
